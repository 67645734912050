import React from "react";
import profile_pic from "../../Assets/profile_pic.png"
import { project_data } from "../../data/data";

function Home() {

  const Card = ({ item }) => {
    return(
      <div className="project-card" onClick={ () => window.open(item.link)}> 
        <div >
          <text className="card-title">{item.title}</text>
        </div>
        <img src={item.img} className="img"/>
        <div >
          <text className="card-title">{item.description}</text>
        </div>
      </div>
    )
  }

  const TextLink = ({link, text}) => {
    return(
      <text> 
        &nbsp; 
        <text className="link-text" onClick={() => window.open(link)}>{text}</text>    
      </text>
    )
  }

  return (
    <section className="home-header" >
      <h1 className="heading-name">Hi. I'm 
        <strong className="main-name"> Te-Yen Chou (Andy)</strong>
      </h1> 
      <h3>
        <strong> CMU MSE Scalable Sys. | TSMC SWE Intern | Boston College MBA </strong>
      </h3>
      <h5>
        <strong> If we can change how data works, we can change the world.</strong>
      </h5>
      <div className="home-intro">
        <div className="home-ctn1">
          <p className="home-about-body">
            Interest: Distributed System | Database 
            <br />
            <br />
            Software Engineer Intern @
            <TextLink text={"Taiwan Semiconductor Manufacturing Company"} link={"https://www.tsmc.com/english"} />
            . (TSMC)
            <br /> 
            <br /> 
              Master of Computer Software Engineering @ 
            <TextLink text={"Carnegie Mellon University,"} link={"https://www.cmu.edu/"} />
            <br /> 
            <br />
            Software Engineer @
            <TextLink text={"CytoAurora Biotechnologies"} link={"https://www.cytoaurora.com/en"} />
            . (Computer vision stuffs)
            <br /> 
            <br />
              Cofounder & Software Engineer @
              <TextLink text={"Quoger"} link={"https://www.linkedin.com/company/quoger/"} />
              <span> </span>
              (digital coupon platform)
            <br />
            <br />
              Computer Science Research Assistant & MBA @ 
              <TextLink text={"Boston College,"} link={"https://www.bc.edu/"} />
            <br /> 
            {/* <br />
              Studied in Agricultural Chemistry @ 
              <TextLink text={"National Taiwan University."} link={"https://www.ntu.edu.tw/english/"} />
            <br />  */}
            <br />
              Built  
              <TextLink text={"Linking Book"} link={"https://github.com/teyenc/linking-book"} /> 
              <span> </span>
            (social platform)
            <br /> 
            {/* <br />
              I'm interested in Distributed System and Database (fun!) 
            <br />  */}
            <br />
              I am also a very good magician. 
            <br /> 
          </p>
          <img
            src={profile_pic}
            alt="home pic"
            className="img-fluid"
          />
        </div>
      </div>
      <div className="project-container">
        {
          project_data.map((item, i) => (
            <Card item={project_data[i]} />
          ))
        }
      </div>
    </section>
  );
}

export default Home;
